import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

// Constants
import { CURATOR_API_URL } from '../constants';

export const getSocialMedias = () => axios.get(
  `${CURATOR_API_URL}/feeds/${process.env.NEXT_PUBLIC_CURATOR_FEED_ID}/posts`,
  {
    params: {
      api_key: process.env.NEXT_PUBLIC_CURATOR_API_KEY,
      limit: 3,
    },
  },
).then((res) => res?.data);

export const useSocialMedias = (options) => useQuery(
  ['social-medias'],
  () => getSocialMedias(),
  { ...options },
);
