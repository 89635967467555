import PropTypes from 'prop-types';
import Head from 'next/head';

// Utils
import { cn } from 'utils';

// Styles
import styles from './SocialMediasBlock.module.css';

// Components
import Title from '../../atoms/Title/Title';
import SocialMediaCard from '../../atoms/SocialMediaCard/SocialMediaCard';
import CardsCarousel from '../../atoms/CardsCarousel/CardsCarousel';

// Services
import { useSocialMedias } from '../../../src/services/social-medias';

function SocialMediasBlock({
  title,
  subtitle,
  theme,
  className,
}) {
  const { data: posts, isLoading } = useSocialMedias({
    select: (res) => res?.posts?.map((item) => ({ data: item })),
  });

  return (
    <>
      <Head>
        <link rel="preconnect" href="https://curator.io" crossOrigin="true" />
      </Head>
      <div className={cn([styles.wrapper, styles[theme], className])}>
        <div className={styles.container}>
          {title ? (
            <Title
              title={title}
              subtitle={subtitle}
              theme={theme}
              className={styles.title}
            />
          ) : null}
          {!isLoading ? (
            <CardsCarousel list={posts} theme={theme} uniqueKey="id">
              {({ data: post }) => (
                <SocialMediaCard
                  media={{
                    url: post?.image,
                    description: post?.text,
                  }}
                  link={post?.url}
                  source={post?.network_name}
                  likes={post?.likes}
                  theme={theme}
                />
              )}
            </CardsCarousel>
          ) : null}
        </div>
      </div>
    </>
  );
}

SocialMediasBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

SocialMediasBlock.defaultProps = {
  title: '',
  subtitle: '',
  theme: 'light',
  className: '',
};

export default SocialMediasBlock;
