import PropTypes from 'prop-types';
import Image from 'next/image';
import Link from 'next/link';

// Utils
import { cn } from 'utils';

// Styles
import styles from './SocialMediaCard.module.css';

// Components
import { Picto } from '../Picto/Picto';

function SocialMediaCard({
  media, source, link, likes, theme,
}) {
  return (
    <Link
      href={link}
      target="_blank"
      className={cn([styles.container, styles[theme]])}
    >
      <div className={styles.image}>
        {source ? (
          <span className={styles.icon}>
            <Picto icon={source.toLowerCase()} />
          </span>
        ) : null}
        {media?.url ? (
          <Image
            src={media.url}
            alt={media?.description}
            fill
            sizes="33vw"
          />
        ) : null}
        <div className={styles.hoverOverlay}>
          <Picto icon="like" />
          <span>{likes}</span>
        </div>
      </div>
    </Link>
  );
}

SocialMediaCard.propTypes = {
  link: PropTypes.string,
  media: PropTypes.shape({
    url: PropTypes.string,
    description: PropTypes.string,
  }),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  source: PropTypes.string,
  likes: PropTypes.number,
};

SocialMediaCard.defaultProps = {
  link: '',
  media: {
    url: '',
    description: '',
  },
  theme: 'light',
  source: '',
  likes: 0,
};

export default SocialMediaCard;
